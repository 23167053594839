
.HomePage {
  background-image: url("../assets/northern-lights-1197755_1920.jpg");
  text-align: center;
  justify-content: center;
  flex-grow:1;
  background-position: center;
  background-color: #1d3557;
  /* height: 100rem; */
  /* max-height:fit-content; */
  background-repeat: no-repeat;
}


.MapBox {
    text-align: center;
    justify-content: flex-start;
    flex-grow:1;
    background-position: center;
    height: 55rem;
  }
  

