


.App {
  /* display: flex;
  flex-direction: column; */
  min-height: 100vh;
  min-width: 100vh;
}

.App-link {
  color: #61dafb;
}


